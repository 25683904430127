import {
  LISTEN_CURRENT_USER_EVENTS
} from "../store/actions"

export default {
  methods: {
    listenCurrentUserEvents: function () {
      let authUser = this.$auth.user()

      if (authUser && authUser.id) {
        this.$store.dispatch(LISTEN_CURRENT_USER_EVENTS, { userId: authUser.id })
      }
    },
  }
}
