<template>
  <div class="main-sidebar-bottom-side">
    <div class="main-sidebar-bottom-side-line"></div>


    <p v-if="checkUserHasClientRole()" class="settings-icon">
      <router-link :to="{ name: 'client.dashboard.settings' }">
        <span v-if="value">Settings</span>
      </router-link>
    </p>


      <a class="support-btn" href="https://einvestment.com/contact-us/" target="_blank" v-if="value">
        <button>
        <img src="@/assets/img/icons/support-sidebar-icon.svg" alt="">
        Support
        </button>
      </a>


    <button @click="handleLogout()">
      <img src="@/assets/img/icons/logout-sidebar-icon.svg" alt="">
      <span v-if="value">Log Out</span>
    </button>
  </div>
</template>

<script>
import {
  UNSUBSCRIBE_FROM_USER_CHANNEL,
} from "@/store/actions"
import {
  REMOVE_ALL_PUSH_NOTIFICATION,
} from "@/store/mutations"
import checkUserRole from "@/mixins/checkUserRole"

export default {
  name: "BottomMenu",
  props:['value'],
  mixins: [
    checkUserRole,
  ],
  methods:{
    handleLogout() {
      this.$store.dispatch(UNSUBSCRIBE_FROM_USER_CHANNEL, { userId: this.$auth.user().id })
      this.$store.commit(REMOVE_ALL_PUSH_NOTIFICATION)
      this.$auth.logout()
    },
  }
}
</script>
