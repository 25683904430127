import moment from 'moment'
import 'moment-timezone'

export default {
  data: function () {
    return {
      /** @link https://www.fusioo.com/guide/using-timezones */
      gmtTimezones: [
        {
          value: -12,
          label: "(GMT-12:00) International Date Line West",
          formatted: "Pacific/Kiritimati",
        },
        {
          value: -11,
          label: "(GMT-11:00) Midway Island, Samoa",
          formatted: "Pacific/Apia",
        },
        {
          value: -10,
          label: "(GMT-10:00) Hawaii",
          formatted: "Pacific/Honolulu",
        },
        {
          value: -9,
          label: "(GMT-09:00) Alaska",
          formatted: "America/Anchorage",
        },
        {
          value: -8,
          label: "(GMT-08:00) Pacific Time (US and Canada)",
          formatted: "America/Los_Angeles",
        },
        {
          value: -7,
          label: "(GMT-07:00) Mountain Time (US and Canada)",
          formatted: "America/Denver",
        },
        {
          value: -7,
          label: "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
          formatted: "America/Denver",
        },
        {
          value: -7,
          label: "(GMT-07:00) Arizona",
          formatted: "America/Denver",
        },
        {
          value: -6,
          label: "(GMT-06:00) Central Time (US and Canada)",
          formatted: "America/Chicago",
        },
        {
          value: -6,
          label: "(GMT-06:00) Saskatchewan",
          formatted: "America/Chicago",
        },
        {
          value: -6,
          label: "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
          formatted: "America/Chicago",
        },
        {
          value: -6,
          label: "(GMT-06:00) Central America",
          formatted: "America/Chicago",
        },
        {
          value: -5,
          label: "(GMT-05:00) Eastern Time (US and Canada)",
          formatted: "America/New_York",
        },
        {
          value: -5,
          label: "(GMT-05:00) Indiana (East)",
          formatted: "America/New_York",
        },
        {
          value: -5,
          label: "(GMT-05:00) Bogota, Lima, Quito",
          formatted: "America/New_York",
        },
        {
          value: -4,
          label: "(GMT-04:00) Atlantic Time (Canada)",
          formatted: "America/Halifax",
        },
        {
          value: -4,
          label: "(GMT-04:00) Caracas, La Paz",
          formatted: "America/Halifax",
        },
        {
          value: -4,
          label: "(GMT-04:00) Santiago",
          formatted: "America/Halifax",
        },
        {
          value: -3.5,
          label: "(GMT-03:30) Newfoundland and Labrador",
          formatted: "America/St_Johns",
        },
        {
          value: -3,
          label: "(GMT-03:00) Brasilia",
          formatted: "America/Sao_Paulo",
        },
        {
          value: -3,
          label: "(GMT-03:00) Buenos Aires, Georgetown",
          formatted: "America/Argentina/Buenos_Aires",
        },
        {
          value: -3,
          label: "(GMT-03:00) Greenland",
          formatted: "America/Argentina/Buenos_Aires",
        },
        {
          value: -2,
          label: "(GMT-02:00) Mid-Atlantic",
          formatted: "Atlantic/South_Georgia",
        },
        {
          value: -1,
          label: "(GMT-01:00) Azores",
          formatted: "Atlantic/Azores",
        },
        {
          value: -1,
          label: "(GMT-01:00) Cape Verde Islands",
          formatted: "Atlantic/Azores",
        },
        {
          value: 0,
          label: "(GMT) Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London",
          formatted: "Europe/Lisbon",
        },
        {
          value: 0,
          label: "(GMT) Casablanca, Monrovia",
          formatted: "Europe/Lisbon",
        },
        {
          value: 1,
          label: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
          formatted: "Europe/Paris",
        },
        {
          value: 1,
          label: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
          formatted: "Europe/Paris",
        },
        {
          value: 1,
          label: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
          formatted: "Europe/Paris",
        },
        {
          value: 1,
          label: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
          formatted: "Europe/Paris",
        },
        {
          value: 1,
          label: "(GMT+01:00) West Central Africa",
          formatted: "Europe/Paris",
        },
        {
          value: 2,
          label: "(GMT+02:00) Bucharest",
          formatted: "Europe/Bucharest",
        },
        {
          value: 2,
          label: "(GMT+02:00) Cairo",
          formatted: "Africa/Cairo",
        },
        {
          value: 2,
          label: "(GMT+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius",
          formatted: "Europe/Kiev",
        },
        {
          value: 2,
          label: "(GMT+02:00) Athens, Istanbul, Minsk",
          formatted: "Europe/Kiev",
        },
        {
          value: 2,
          label: "(GMT+02:00) Jerusalem",
          formatted: "Europe/Kiev",
        },
        {
          value: 2,
          label: "(GMT+02:00) Harare, Pretoria",
          formatted: "Europe/Kiev",
        },
        {
          value: 3,
          label: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
          formatted: "Europe/Moscow",
        },
        {
          value: 3,
          label: "(GMT+03:00) Kuwait, Riyadh",
          formatted: "Europe/Moscow",
        },
        {
          value: 3,
          label: "(GMT+03:00) Nairobi",
          formatted: "Europe/Moscow",
        },
        {
          value: 3,
          label: "(GMT+03:00) Baghdad",
          formatted: "Europe/Moscow",
        },
        {
          value: 3.5,
          label: "(GMT+03:30) Tehran",
          formatted: "Asia/Tehran",
        },
        {
          value: 4,
          label: "(GMT+04:00) Abu Dhabi, Muscat",
          formatted: "Asia/Dubai",
        },
        {
          value: 4,
          label: "(GMT+04:00) Baku, Tbilisi, Yerevan",
          formatted: "Asia/Dubai",
        },
        {
          value: 4.5,
          label: "(GMT+04:30) Kabul",
          formatted: "Asia/Kabul",
        },
        {
          value: 5,
          label: "(GMT+05:00) Ekaterinburg",
          formatted: "Asia/Karachi",
        },
        {
          value: 5,
          label: "(GMT+05:00) Islamabad, Karachi, Tashkent",
          formatted: "Asia/Karachi",
        },
        {
          value: 5.5,
          label: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
          formatted: "Asia/Kolkata",
        },
        {
          value: 5.75,
          label: "(GMT+05:45) Kathmandu",
          formatted: "Asia/Kolkata",
        },
        {
          value: 6,
          label: "(GMT+06:00) Astana, Dhaka",
          formatted: "Asia/Almaty",
        },
        {
          value: 6,
          label: "(GMT+06:00) Sri Jayawardenepura",
          formatted: "Asia/Almaty",
        },
        {
          value: 6,
          label: "(GMT+06:00) Almaty, Novosibirsk",
          formatted: "Asia/Almaty",
        },
        {
          value: 6.5,
          label: "(GMT+06:30) Yangon Rangoon",
          formatted: "Asia/Yangon",
        },
        {
          value: 7,
          label: "(GMT+07:00) Bangkok, Hanoi, Jakarta",
          formatted: "Asia/Bangkok",
        },
        {
          value: 7,
          label: "(GMT+07:00) Krasnoyarsk",
          formatted: "Asia/Bangkok",
        },
        {
          value: 8,
          label:  "(GMT+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi",
          formatted: "Asia/Shanghai",
        },
        {
          value: 8,
          label: "(GMT+08:00) Kuala Lumpur, Singapore",
          formatted: "Asia/Shanghai",
        },
        {
          value: 8,
          label: "(GMT+08:00) Taipei",
          formatted: "Asia/Shanghai",
        },
        {
          value: 8,
          label: "(GMT+08:00) Perth",
          formatted: "Asia/Shanghai",
        },
        {
          value: 8,
          label: "(GMT+08:00) Irkutsk, Ulaanbaatar",
          formatted: "Asia/Shanghai",
        },
        {
          value: 9,
          label: "(GMT+09:00) Seoul",
          formatted: "Asia/Tokyo",
        },
        {
          value: 9,
          label: "(GMT+09:00) Osaka, Sapporo, Tokyo",
          formatted: "Asia/Tokyo",
        },
        {
          value: 9,
          label: "(GMT+09:00) Yakutsk",
          formatted: "Asia/Tokyo",
        },
        {
          value: 9.5,
          label: "(GMT+09:30) Darwin",
          formatted: "Australia/Adelaide",
        },
        {
          value: 9.5,
          label: "(GMT+09:30) Adelaide",
          formatted: "Australia/Adelaide",
        },
        {
          value: 10,
          label: "(GMT+10:00) Canberra, Melbourne, Sydney",
          formatted: "Australia/Sydney",
        },
        {
          value: 10,
          label: "(GMT+10:00) Brisbane",
          formatted: "Australia/Sydney",
        },
        {
          value: 10,
          label: "(GMT+10:00) Hobart",
          formatted: "Australia/Sydney",
        },
        {
          value: 10,
          label: "(GMT+10:00) Vladivostok",
          formatted: "Australia/Sydney",
        },
        {
          value: 10,
          label: "(GMT+10:00) Guam, Port Moresby",
          formatted: "Australia/Sydney",
        },
        {
          value: 11,
          label: "(GMT+11:00) Magadan, Solomon Islands, New Caledonia",
          formatted: "Asia/Magadan",
        },
        {
          value: 12,
          label: "(GMT+12:00) Fiji Islands, Kamchatka, Marshall Islands",
          formatted: "Pacific/Auckland",
        },
        {
          value: 12,
          label: "(GMT+12:00) Auckland, Wellington",
          formatted: "Pacific/Auckland",
        },
        {
          value: 13,
          label: "(GMT+13:00) Nuku'alofa",
          formatted: "Pacific/Tongatapu",
        },
      ]
    }
  },
  methods: {
    setDateTimezone(date) {
      let user = this.$auth.user()

      if (user) {
        let userTimeZone = user.timezone ?? ''
        let momentDate = moment(date.toISOString())

        if (userTimeZone) {
          let timezoneObj = this.gmtTimezones.find(obj => {
            return obj.label === userTimeZone
          })

          momentDate.tz(timezoneObj.formatted)
        }

        return momentDate.toDate()
      }

      return date
    },
  },
}
