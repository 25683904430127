import router from "../router"

export default {
  async beforeRouteEnter (to, from, next) {
    const token = localStorage.getItem('auth_token_default')

    if (token && (to.meta.admin_role_needed || to.meta.client_role_needed)) {
      if (!router.app.$auth.user()) {
        await router.app.$auth.fetch()
      }

      let user = router.app.$auth.user()

      if (
        to.meta.admin_role_needed && !user?.roles?.includes(process.env.VUE_APP_ADMIN_ROLE_NAME) ||
        to.meta.client_role_needed && !user?.roles?.includes(process.env.VUE_APP_CLIENT_ROLE_NAME)
      ) {
        let redirect = to.meta.admin_role_needed && !user?.roles?.includes(process.env.VUE_APP_ADMIN_ROLE_NAME)
          ? 'client.dashboard.index'
          : 'admin.dashboard.index'
        next({ name: redirect }) // 'page-not-found'
      } else {
        next()
      }
    }

    next()
  },
  methods: {
    //
  }
}
