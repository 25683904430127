<template>
  <main id="user-dashboard-container" class="col-xs-12" :class="{full: !openSidebar}">
    <aside id="main-sidebar" :class="{hide: !openSidebar}">
      <TheSidebar
        @toggleSidebar="changeSidebarState()"
        v-model="openSidebar"/>
    </aside>

    <div id="user-dashboard-content" class="content" :class="{hide: !openSidebar}">
<!--      <top-menu :is-dashboard="true" @toggleDrawer="mini = ! mini"/>-->
      <div class="user-dashboard-wrap">
        <HeaderDashboard/>
        <div :class="`user-dashboard-info-block user-dashboard-info-${$router.currentRoute.name.replace('.', '_')}-block`">
          <slot></slot>
        </div>
      </div>
    </div>

    <PushAlertsPopUp/>
<!--    v-if="pushNotificationsList && pushNotificationsList.length > 0"-->
  </main>
</template>

<script>
// import TopMenu from "../components/TopBar"
import TheSidebar from "@/components/basic/TheSidebar"
import HeaderDashboard from "@/components/dashboard/HeaderDashboard"
import listenCurrentUserEvents from "../mixins/listenCurrentUserEvents"
import PushAlertsPopUp from "@/components/basic/PushAlertsPopUp"
import {mapGetters} from "vuex"

export default {
  name: "user-dashboard-layout",
  components: {
    PushAlertsPopUp,
    HeaderDashboard,
    TheSidebar,
    // TopMenu,
  },
  mixins: [
    listenCurrentUserEvents,
  ],
  computed: {
    ...mapGetters([
      'pushNotificationsList',
    ])
  },
  data: function () {
    return {
      mini: false,
      openSidebar: true,
    }
  },
  beforeMount() {
    if (localStorage.openSidebar) this.openSidebar = JSON.parse(localStorage.openSidebar)

    this.listenCurrentUserEvents()
  },
  watch: {
    openSidebar(openSidebar) {
      localStorage.openSidebar = openSidebar
    },
  },
  methods: {
    changeSidebarState() {
      this.openSidebar = !this.openSidebar
    },
  }
}
</script>
