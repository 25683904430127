<template>
  <div class="push-alert-container dashboard-ntf-pop_up-content">
    <div
      v-for="pushNotification in pushNotificationsList"
      :key="pushNotification.id"
      class="push-alert-wrap dashboard-ntf-pop_up-wrap">
      <img :src="checkNtfIcon(pushNotification.notifiable_type)" alt="">
      <div class="dashboard-ntf-pop_up-wrap-content">
        <h4>{{isEmptyField(pushNotification, 'title') }}</h4>
        <p>{{ isEmptyField(pushNotification, 'message') }}</p>
      </div>
      <div class="dashboard-ntf-pop_up-wrap-close">
        <button @click="closePushNotification(pushNotification.id)" title="close"><img src="@/assets/img/icons/close_icon.svg" alt=""></button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import isEmptyField from "@/mixins/isEmptyField"
import {REMOVE_PUSH_NOTIFICATION} from "@/store/mutations"

export default {
  name: "PushAlertsPopUp",
  mixins:[
    isEmptyField,
  ],
  computed: {
    ...mapGetters([
      'pushNotificationsList',
    ])
  },
  methods:{
    closePushNotification(id){
      this.$store.commit(REMOVE_PUSH_NOTIFICATION, id)
    },
    checkNtfIcon(ntfType){
      switch (ntfType){
      case 'App\\Models\\Deposit':
        return require('@/assets/img/icons/deposit_ntf_icon.svg')
      case 'App\\Models\\WithdrawRequest':
        return require('@/assets/img/icons/withdrawal_ntf_icon.svg')
      case 'App\\Models\\DepositInterest':
        return require('@/assets/img/icons/percent_ntf_icon.svg')
      default:
        return false
      }
    },
  }
}
</script>
