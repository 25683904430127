<template>
  <div class="main-sidebar-container">
    <div class="main-sidebar-head">
      <transition name="fade" mode="out-in">
        <img v-if="value" src="@/assets/img/logo/logo-white.svg" alt="">
        <img v-else src="@/assets/img/logo/logo_mini.svg" alt="">
      </transition>

      <transition name="fade" mode="out-in">
       <p v-if="value && $auth.user()" >Last Access: {{convertLastLoginDate($auth.user().last_login_at)}}</p>
      </transition>

      <button
        class="toggle_sidebar"
        @click.prevent="$emit('toggleSidebar')">
        <img src="@/assets/img/icons/toggle_sidebar-icon.svg" alt="">
      </button>

    </div>

    <SidebarNavigation :openSidebar="value"/>

    <BottomMenu v-if="toggleBottomMenuStatus()" :value="value"/>
  </div>
</template>

<script>
import SidebarNavigation from "@/components/basic/SidebarNavigation"
import BottomMenu from "@/components/basic/BottomMenu"
import gmtTimezones from "@/mixins/gmtTimezones"
// import HelperFunctions from "@/common/helperFunctions"


export default {
  name: 'TheSidebar',
  components: {
    BottomMenu,
    SidebarNavigation,
  },
  mixins: [
    gmtTimezones
  ],
  props: ['value'],
  methods:{
    toggleBottomMenuStatus(){
      return window.innerWidth > 961
    },
    convertLastLoginDate(date){
      // return HelperFunctions.convertDate(new Date(date))
      let newDate = this.setDateTimezone(new Date(date))

      let yyyy = newDate.getFullYear().toString()
      let mm = (newDate.getMonth()+1).toString()
      let dd  = newDate.getDate().toString()
      let hh  = newDate.getHours().toString()
      let min  = newDate.getMinutes().toString().padStart(2, '0')

      let mmChars = mm.split('')
      let ddChars = dd.split('')

      return (ddChars[1]?dd:"0"+ddChars[0]) + '.' + (mmChars[1]?mm:"0"+mmChars[0]) + '.' + yyyy + ' ' + hh + ':' + min
    }
  }
}
</script>

<style scoped>
.fade-enter-active{
  transition: all .15s ease-out .2s;
}

.fade-enter{
  opacity: 0;
}
</style>
