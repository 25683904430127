export default {
  methods: {
    checkUserEmailVerified(){
      let user = this.$auth.user()

      return user ? user.is_email_verified : false
    },
    checkUserPhoneVerified(){
      let user = this.$auth.user()

      return user ? user.is_phone_verified : false
    },
    checkUserDocumentsVerified(){
      let user = this.$auth.user()

      return user ? user.is_documents_verified : false
    },
    checkUserDocumentsVerificationInProgress(){
      let user = this.$auth.user()

      return user ? user.has_pending_kyc_request : false
    },
  }
}
