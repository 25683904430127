export default {
  methods: {
    isEmptyField: function (el, key, placeholder = '-') {
      if (!el || !key) return placeholder
      return (el[key] !== null && el[key] !== '' && el[key] !== undefined) ? el[key] : placeholder
    },
    isEmptyFieldWithoutSymbol: function (el, key, placeholder = '') {
      if (!el || !key) return placeholder
      return (el[key] !== null && el[key] !== '' && el[key] !== undefined) ? el[key] : placeholder
    }
  }
}
