<template>
  <nav>
    <div class="main-sidebar-nav scroll-content-wrap scrollbar-container">
      <ul class="main-sidebar-nav-general">
        <template v-for="item in menuItems">
            <li v-if="!item.verification_needed || (item.verification_needed && checkUserDocumentsVerified())" :key="item.name" :class="item.img_class">
              <router-link :to="{ name: item.name }">
                <span v-if="toggleSidebarStatus()">{{ item.title }}</span>
              </router-link>
            </li>
        </template>
      </ul>
    </div>
  </nav>
</template>

<script>
import checkUserVerification from "../../mixins/checkUserVerification"
import checkUserRole from "@/mixins/checkUserRole"

export default {
  name: 'SidebarNavigation',
  props: ['openSidebar'],
  mixins: [
    checkUserVerification,
    checkUserRole,
  ],
  computed: {
    menuItems() {
      return this.checkUserHasAdminRole()
        ? this.menuAdminItems
        : this.menuUserItems
    },
  },
  data() {
    return {
      menuAdminItems:[
        {
          name: 'admin.dashboard.index',
          title: 'Dashboard',
          img_class: 'dashboard_icon',
        },
        {
          name: 'admin.dashboard.portfolios',
          title: 'Portfolios',
          img_class: 'dashboard_icon',
        },
        {
          name: 'admin.dashboard.users',
          title: 'Users',
          img_class: 'dashboard_icon',
        },
        {
          name: 'admin.dashboard.kyc',
          title: 'KYC Requests',
          img_class: 'dashboard_icon',
        },
        {
          name: 'admin.dashboard.withdrawals',
          title: 'Withdrawals',
          img_class: 'dashboard_icon',
        },
        {
          name: 'admin.dashboard.deposits',
          title: 'Deposits',
          img_class: 'dashboard_icon',
        },
      ],

      menuUserItems: [
        {
          name: 'client.dashboard.index',
          title: 'Dashboard',
          img_class: 'dashboard_icon',
          verification_needed: false,
        },
        {
          name: 'client.dashboard.invest',
          title: 'Invest',
          img_class: 'invest_icon',
          verification_needed: true,
        },
        {
          name: 'client.dashboard.withdraw',
          title: 'Withdraw Funds',
          img_class: 'funds_icon',
          verification_needed: true,
        },
        {
          name: 'client.dashboard.statement',
          title: 'Statement',
          img_class: 'statement_icon',
          verification_needed: true,
        },
        {
          name: 'client.dashboard.pending',
          title: 'Pending Withdrawals',
          img_class: 'withdrawals_icon',
          verification_needed: true,
        },
      ]
    }
  },
  methods: {
    toggleSidebarStatus(){
      return window.innerWidth < 961 ? true : this.openSidebar
    }
  }
}
</script>
