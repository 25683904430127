<template>
  <div class="header-dashboard-container">
    <div class="header-dashboard-container-left-side">
      <div class="header-dashboard-container-name">
        <h3 v-if="$auth.user()">Hello, {{ userDisplayName }}</h3>
        <p v-if="checkUserDocumentsVerificationInProgress()" class="verification-wrap verification-wrap-pending">
          <img title="(pending verification)" src="@/assets/img/icons/not-verified-icon.svg" alt=""/>
<!--          <span>(pending verification)</span>-->
        </p>
        <p v-else-if="checkUserDocumentsVerified()" class="verification-wrap verification-wrap-verified">
          <img title="(verified)" src="@/assets/img/icons/verification-icon.svg" alt=""/>
<!--          <span>(verified)</span>-->
        </p>
        <p v-else class="verification-wrap verification-wrap-unverified" title="not verified">
          <img title="(not verified)" src="@/assets/img/icons/not-verified-icon.svg" alt=""/>
<!--          <span>(not verified)</span>-->
        </p>
      </div>

      <div class="header-dashboard-container-info">
        <p>Welcome back, nice to see you again!</p>
      </div>
    </div>

    <div v-if="checkUserHasClientRole()" class="header-dashboard-container-right-side">
      <div @click.prevent="toggleNotifyPopUp()" class="header-dashboard-container-ntf">
        <img src="@/assets/img/icons/ntf-icon.svg" alt=""/>
        <span v-if="notificationsCount > 0">{{ notificationsCount }}</span>
      </div>

      <NotificationsPopUp v-if="toggleNotifyPopUpState"/>

<!--      <div class="header-dashboard-container-updates">-->
<!--        <button>2 new updates</button>-->
<!--      </div>-->

      <div class="header-dashboard-container-user-info">
        <img :src="getUserAvatar()" alt=""/>
        <button :class="{open: userMenuState}" @click="userMenuState = !userMenuState" class="more-user-info"></button>

        <div v-if="userMenuState" class="header-dashboard-container-user-menu">
          <BottomMenu v-if="toggleMenuStatus()" :value="true"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BottomMenu from "@/components/basic/BottomMenu"
import checkUserVerification from "../../mixins/checkUserVerification"
import NotificationsPopUp from "@/components/basic/popups/NotificationsPopUp"
import {FETCH_USER_NOTIFICATIONS_LIST} from "@/store/actions"
import {mapGetters} from "vuex"
import checkUserRole from "@/mixins/checkUserRole"
// import {ADD_NEW_NOTIFICATIONS_COUNT} from "@/store/mutations"

export default {
  name: "HeaderDashboard",
  components: {NotificationsPopUp, BottomMenu},
  mixins: [
    checkUserVerification,
    checkUserRole,
  ],
  computed: {
    ...mapGetters([
      'userNotificationsList',
      'notificationsCount',
    ]),
    userDisplayName() {
      let currentUser = this.$auth.user()
      let userName

      if (currentUser && currentUser.account_type === 'institutional' && currentUser.company_info && currentUser.company_info.company_name) {
        userName = currentUser.company_info.company_name
      } else {
        userName = `${currentUser.first_name} ${currentUser.last_name}`
      }

      return userName
    },
  },
  data(){
    return{
      userMenuState: false,
      toggleNotifyPopUpState: false,
    }
  },
  mounted () {
    this.fetchUserNotificationsList()
    document.addEventListener('click', this.close)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.close)
  },
  methods:{
    fetchUserNotificationsList() {
      return this.$store.dispatch(FETCH_USER_NOTIFICATIONS_LIST)
    },
    toggleNotifyPopUp(){
      this.toggleNotifyPopUpState = !this.toggleNotifyPopUpState
    },
    close (e) {
      if (!this.$el.contains(e.target)) {
        this.toggleNotifyPopUpState = false
      }
    },
    toggleMenuStatus(){
      // return window.innerWidth < 961
      return true
    },
    getUserAvatar(){
      let userAvatar = this.$auth.user().avatar_url

      return userAvatar
        ? process.env.VUE_APP_API_URL + userAvatar
        : require('@/assets/img/user_photo_example.png')
    },
  }
}
</script>
