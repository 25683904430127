<template>
  <div class="dashboard-ntf-pop_up">
      <div class="dashboard-ntf-pop_up-head">
        <h4>Notifications</h4>
        <span v-if="notificationsCount > 0">{{notificationsCount}} New</span>
      </div>

    <template v-if="userNotificationsList && userNotificationsList.length > 0">
      <div class="dashboard-ntf-pop_up-content scrollbar-container">
        <div
          v-for="userNotification in sortedNotificationsList"
          :key="userNotification.id"
          :class="{old: userNotification.was_read}"
          class="dashboard-ntf-pop_up-wrap">
          <div class="dashboard-ntf-pop_up-wrap-icon">
            <img :src="checkNtfIcon(userNotification.notifiable_type)" alt="">
          </div>
          <div class="dashboard-ntf-pop_up-wrap-content">
            <h4>{{isEmptyField(userNotification, 'title') }}</h4>
            <p>{{ isEmptyField(userNotification, 'message') }}</p>
          </div>
          <div class="dashboard-ntf-pop_up-wrap-date">
            <span>{{convertNotificationDate(userNotification.created_at)}}</span>
          </div>
        </div>
      </div>
      <div class="dashboard-ntf-pop_up-btn">
        <button v-if="readAllButtonVisible" @click="readAllNotifications()" class="basic_green-btn">
          Mark all as read
        </button>
      </div>
    </template>

    <template v-else>
      <p class="no_ntf">You have no notifications!</p>
    </template>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import isEmptyField from "@/mixins/isEmptyField"
import {
  READ_ALL_USER_NOTIFICATIONS,
} from "@/store/actions"
import {
  REMOVE_ALL_PUSH_NOTIFICATION,
} from "@/store/mutations"

export default {
  name: "NotificationsPopUp",
  mixins:[
    isEmptyField,
  ],
  computed: {
    ...mapGetters([
      'userNotificationsList',
      'notificationsCount',
    ]),

    sortedNotificationsList: function () {
      let newNotificationsList = this.userNotificationsList

      return newNotificationsList.sort(function(a, b) {
        if ( a.was_read < b.was_read ){
          return -1
        } else if ( a.was_read > b.was_read ){
          return 1
        } else {
          return 0
        }
      })
    },
  },
  data(){
    return{
      readAllButtonVisible: false,
    }
  },
  mounted() {
    this.sortedNotificationsList.filter((ntf) => {
      if(!ntf.was_read) this.readAllButtonVisible = true
    })
  },
  methods:{

    readAllNotifications(){
      this.$store.dispatch(READ_ALL_USER_NOTIFICATIONS, []).finally(
        this.readAllButtonVisible = false
      )
      this.$store.commit(REMOVE_ALL_PUSH_NOTIFICATION)
    },
    convertNotificationDate(date){
      let newDate = new Date(date)
      var yyyy = newDate.getFullYear().toString().substr(-2)
      var mm = (newDate.getMonth()+1).toString()
      var dd  = newDate.getDate().toString()

      var mmChars = mm.split('')
      var ddChars = dd.split('')

      return (ddChars[1]?dd:"0"+ddChars[0]) + '/' + (mmChars[1]?mm:"0"+mmChars[0]) + '/' + yyyy
    },
    checkNtfIcon(ntfType){
      switch (ntfType){
      case 'App\\Models\\Deposit':
        return require('@/assets/img/icons/deposit_ntf_icon.svg')
      case 'App\\Models\\WithdrawRequest':
        return require('@/assets/img/icons/withdrawal_ntf_icon.svg')
      case 'App\\Models\\DepositInterest':
        return require('@/assets/img/icons/percent_ntf_icon.svg')
      default:
        return false
      }
    },
  }
}
</script>
